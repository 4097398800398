@tailwind base;
@tailwind components;
@tailwind utilities;

.navLink {
    @apply mr-5 hover:text-sky-500 dark:hover:text-sky-400 inline-block ;
}

.searchInput {
    @apply bg-gray-200 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-900 text-base outline-none text-gray-800 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
    text-align: center;
}

svg {
    @apply text-black stroke-black;
}

.button {
    @apply flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-sm;
}

.externalIconsOnSearch {
    @apply rounded-full ml-2 border border-slate-700 text-center sm:h-6 w-auto sm:w-6 flex gap-1 text-sm   cursor-pointer relative;
}
.externalIconsOnSearch svg {
    @apply h-5 w-5 m-0.5 fill-black dark:fill-white;
}

.dark {
    color-scheme: dark;
}

.darkModeSwitch {
    @apply relative inline-block w-16 h-8;
  }
  
  /* Hide default HTML checkbox */
  .darkModeSwitch input {
    @apply opacity-0 w-0 h-0;
  }
  
  /* The darkModeSlider */
  .darkModeSlider {
    @apply absolute cursor-pointer top-0 left-0 right-1.5 bottom-0 bg-gray-300 transition-all;
  }
  
  .darkModeSlider:before {
    @apply absolute w-6 h-6 left-1 right-0 bottom-1 bg-white transition-all;
    content: "";
  }
  
  input:checked + .darkModeSlider {
    background-color: #2196f3;
  }
  
  input:focus + .darkModeSlider {
    box-shadow: 0 0 1px #2196f3;
  }
  
  input:checked + .darkModeSlider:before {
    @apply translate-x-6;
  }
  
  /* Rounded darkModeSliders */
  .darkModeSlider.round {
    @apply rounded-3xl;
  }
  
  .darkModeSlider.round:before {
    @apply rounded-full;
  }


/* Tooltip styles */
[data-tooltip] {
  @apply relative;
}

[data-tooltip]::before,
[data-tooltip]::after {
  @apply transform-none absolute hidden opacity-0;
}

[data-tooltip]::before {
  @apply content-[""] border z-10;
  border: 5px solid transparent !important; 

}

[data-tooltip]::after {
  @apply content-[attr(data-tooltip)] min-w-min max-w-md whitespace-nowrap overflow-hidden text-ellipsis p-2 rounded-xl shadow-lg bg-slate-300 dark:bg-slate-700 text-slate-700 dark:text-slate-300 z-10;
  
}

/* Make the tooltips respond to hover */
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  @apply block;
}

/* don't show empty tooltips */
[data-tooltip='']::before,
[data-tooltip='']::after {
  @apply hidden;
}

/* FLOW: UP */
[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow^="up"]::before {
  @apply bottom-full;
}
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="up"]::after {
  bottom: calc(100% + 5px) !important;
}
[data-tooltip]:not([data-flow])::before,
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="up"]::before,
[data-tooltip][data-flow^="up"]::after {
  @apply left-1/2;
  transform: translate(-50%, -.5em) !important;
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  @apply border-b-slate-700;
  top: 100%;
  border-top-width: 0;
  /* border-bottom-color: #333; */
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[data-tooltip][data-flow^="left"]::before {
  
  top: 50% !important;
  border-right-width: 0 !important;
  border-right-color: #333;
  left: calc(0em - 5px) !important;
  transform: translate(-.5em, -50%) !important;
}
[data-tooltip][data-flow^="left"]::after {
  top: 50% !important;
  right: calc(100% + 5px) !important;
  transform: translate(-.5em, -50%) !important;
}

/* FLOW: RIGHT */
[data-tooltip][data-flow^="right"]::before {
  top: 50% !important;
  border-left-width: 0 !important;
  border-right-color: #333 !important;
  right: calc(0em - 5px) !important;
  transform: translate(.5em, -50%) !important;
}
[data-tooltip][data-flow^="right"]::after {
  top: 50% !important;
  left: calc(100% + 5px) !important;
  transform: translate(.5em, -50%) !important;
}

@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

[data-tooltip]:not([data-flow]):hover::before,
[data-tooltip]:not([data-flow]):hover::after,
[data-tooltip][data-flow^="up"]:hover::before,
[data-tooltip][data-flow^="up"]:hover::after,
[data-tooltip][data-flow^="down"]:hover::before,
[data-tooltip][data-flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[data-tooltip][data-flow^="left"]:hover::before,
[data-tooltip][data-flow^="left"]:hover::after,
[data-tooltip][data-flow^="right"]:hover::before,
[data-tooltip][data-flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

